<!-- 组件说明 -->
<template>
  <div class="container">
    <div class="title">免责声明</div>
    <div class="content" style="margin-bottom: 20px"> 本网站（www.scmzbl.com）上为版权人所有的文件，在此并未做任何授权，本网站上的文件仅限于为信息和非商业或个人之目的使用，并且不得在任何网络计算机上复制或公布，也不得在任何媒体上传播。未经版权人许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则版权人将依法追究其法律责任。</div>
    <div class="content">免责声明：版权人拥有对本网站的内容进行随意更改之权利，是前将不会另行通知。版权人不保证或声明本网站展示的资料是否正确、全面或是最新数据。本网站内所有图片、模型、文字描述等，仅作为参考信息</div>
  </div>
</template>

<script>
//import x from ''
export default {
  name: 'statement',
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="scss">
.container {
    padding-bottom: 30px;
    .title {
        font-size: 30px;
        padding-bottom: 20px;
        padding-top: 35px;
        text-align: center;
    }
    .content {
        text-indent: 2em;
        font-size: 20px;
        font-family: 'Microsoft YaHei';
        letter-spacing: 2px;
        line-height: 25px;
        text-align: justify;
    }
}
</style>
